
import SaveButton from '../../../save-button.vue';
import { columnLabels } from './constants';
import ColumnList from './column-list.vue';
import ModalHeader from '~/components/modal-header.vue';
import apiRequest from '~/components/helpers/api-request';

export default {
    name: 'UpdateColumnsModal',
    components: { SaveButton, ModalHeader, ColumnList },
    mixins: [apiRequest],
    props: {
        initialHiddenColumns: {
            type: Array,
            required: true,
        },
        targetingGroup: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            // all columns are visible by default
            statusByColumn: Object.fromEntries(Object.keys(columnLabels).map((key) => [key, true])),
            loading: false,
        };
    },
    watch: {
        initialHiddenColumns: {
            handler() {
                this.initialHiddenColumns.forEach((column) => {
                    this.statusByColumn[column] = false;
                });
            },
            immediate: true,
        },
    },
    methods: {
        submitForm() {
            this.loading = true;

            const hiddenColumns = [];
            for (const [key, value] of Object.entries(this.statusByColumn)) {
                if (!value) {
                    hiddenColumns.push(key);
                }
            }

            this.patchAPI(`/targeting_groups/${this.targetingGroup.id}`, {
                hiddenColumns,
            })
                .then((response) => {
                    this.$emit('columns-updated', response.data);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
