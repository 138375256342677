
import SaveButton from "../save-button.vue";
import LoadingSpinner from "../loading-spinner.vue";
import ModalHeader from '~/components/modal-header.vue';

export default {
    name: "SyncCompanyToSessionsModal",
    components: {
        LoadingSpinner,
        SaveButton,
        ModalHeader
    },
    props: {
        event: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            loading: false,
            selectedCompanyGroup: null,
            companiesLoaded: false,
            companiesToEvent: [],
            companiesToSessions: [],
            syncErrors: [],
            tracks: null,
            selectedTrack: null,
            eventHasCompanyGroup: false,
            eventUpdated: false
        }
    },
    watch: {
        selectedCompanyGroup(cg) {
            const existingGroupIds = this.event.companyGroups.map((cg) => cg.id);
            this.eventHasCompanyGroup = existingGroupIds.includes(cg.id);

            this.$axios.get(`/api/events/${this.event.id}/sessions_companies_sync_check/${cg.id}`).then((res) => {
                this.companiesToEvent = res.data.event;
                this.companiesToSessions = res.data.sessions;
                this.companiesLoaded = true;
            })
        }
    },
    mounted() {
        this.$axios.get(`/api/events/${this.event.id}/sessions_tracks`).then((res) => {
            this.tracks = res.data.map((track) => {
                return {
                    id: track.id,
                    label: `${track.code} - ${track.type}`
                }
            });
        });

        // cannot use unmounted because the component stays in the DOM
        this.$refs.modal.addEventListener('hidden.bs.modal', () => {
            if (this.eventUpdated) {
                this.$emit('event-updated');
            }
        });
    },
    methods: {
        handleSubmit() {
            this.loading = true;
            const toastId = this.generateUUID();

            this.$axios.post(`/api/events/${this.event.id}/sessions_companies_sync_trigger/${this.selectedCompanyGroup.id}`, {
                trackId: this.selectedTrack.id
            }).then(() => {
                this.addToast({
                    type: "success",
                    title: "Adding successful",
                    message: "The companies from the list have been pushed to Sessions and added to the event.",
                    id: toastId,
                });
                this.$emit('company-updated');
            }).catch((e) => {
                const res = e.response;
                this.syncErrors = res.data.errors;
                this.addToast({
                    type: "danger",
                    title: `Adding ${res.data.synced > 0 ? 'partially' : ''} failed`,
                    message: "When syncing companies to Sessions, some companies could not be added.",
                    id: toastId,
                });
            }).finally(() => {
                this.loading = false;
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                })
            });
        },
        addGroupToEvent() {
            this.$axios.post(`/api/events/${this.event.id}/add_company_group/${this.selectedCompanyGroup.id}`).then((res) => {
                const toastId = this.generateUUID();
                this.eventHasCompanyGroup = true;
                this.eventUpdated = true;

                this.addToast({
                    type: "success",
                    title: "Adding successful",
                    message: res.data.message,
                    id: toastId,
                });
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                })
            });
        }
    }
}
