
import { mapActions } from 'vuex';
import TableFilters from '../../table-filters.vue';
import QuintableSearchInput from '../../helpers/quintable-search-input.vue';
import TableActions from '../../table-actions.vue';
import ContactName from '../../contacts/contact-name.vue';
import fileDownload from '../../../mixins/file-download';
import {
    CLOSED_HCW_STATUSES,
    OPEN_HCW_STATUSES,
    TARGETING_HCW_STATUS_OPTIONS,
} from '../../../mixins/targeting-hcw-status';
import FancyTooltip from '../../fancy-tooltip.vue';
import modals from '../../../mixins/modals';
import ShowNoteModal from '../../notes/show-note-modal.vue';
import DownloadSelectColumnsModal from '../../download-select-columns-modal.vue';
import ManageEditorsModal from '../../investor-targets/manage-editors-modal.vue';
import AutosaveCellBool from './autosave-cell-bool.vue';
import AutosaveCellText from './autosave-cell-text.vue';
import AutosaveCellDate from './autosave-cell-date.vue';
import AutosaveCellSelect from './autosave-cell-select.vue';
import AddMemberModal from './add-member-modal.vue';
import UpdateColumnsModal from './update-columns-modal/modal.vue';
import AddCompanyContactModal from './add-company-contact-modal.vue';
import TargetingGroupMemberBulkModal from './targeting-group-member-bulk-modal.vue';
import DownloadTargetingMemberGroupModal from './targeting-member-group-download.vue';
import AutosaveCellTextSuggest from './autosave-cell-text-suggest.vue';
import AutosaveCellUser from './autosave-cell-user.vue';

export default {
    name: 'TargetingGroupMembersTable',
    components: {
        AutosaveCellTextSuggest,
        FancyTooltip,
        ManageEditorsModal,
        DownloadTargetingMemberGroupModal,
        DownloadSelectColumnsModal,
        ShowNoteModal,
        TargetingGroupMemberBulkModal,
        AddCompanyContactModal,
        UpdateColumnsModal,
        AddMemberModal,
        AutosaveCellText,
        AutosaveCellBool,
        AutosaveCellDate,
        AutosaveCellSelect,
        ContactName,
        TableActions,
        QuintableSearchInput,
        TableFilters,
        AutosaveCellUser,
    },
    mixins: [fileDownload, modals],
    props: {
        targetingGroup: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            tableUpdate: null,
            modal: null,
            hcwAdditionalNames: null,
            onCompanyMetWithList: null,
            onCompanyTargetList: null,
            cdaSigned: null,
            dataRoomAccessed: null,
            hcwStatuses: [],
            funds: [],
            contacts: [],
            TARGETING_HCW_STATUS_OPTIONS,
            addMemberModalKey: 'addMemeberModalKey',
            updateColumnsModalKey: 'update-columns-modal-key',
            hiddenColumns: [],
            targetingGroupMemberToEdit: null,
            sortOrder: [
                {
                    index: 0,
                    asc: true,
                },
            ],
            isUpdatingShareholderInfo: false,
            selectOpen: false,
            ajaxRows: [],

            noteToShow: null,
            noteToShowKey: 'noteToShowKey',

            // Bulk actions
            preSelectedRowIds: [],
            selectedRows: [],
            preSelectedRows: [],
            pageChanged: false,
            targetingGroupModalKey: 'targetingGroupModalKey',
            isLoading: false,
            bulkContacts: null,
            bulkMembers: null,
            bulkCompanies: null,
            downloadQuery: null,
            groupDownloadQuery: null,

            tooltipLeft: 0,
            tooltipTop: 0,
            tooltipLock: false,
            tooltipShown: false,
        };
    },
    computed: {
        filters() {
            return {
                targetingGroupId: this.targetingGroup.id,
                hcwAdditionalNames: this.hcwAdditionalNames?.value,
                onCompanyMetWithList: this.onCompanyMetWithList?.value,
                onCompanyTargetList: this.onCompanyTargetList?.value,
                cdaSigned: this.cdaSigned?.value,
                dataRoomAccessed: this.dataRoomAccessed?.value,
                hcwStatuses: this.hcwStatuses.map((status) => status.value),
                funds: this.funds.map((fund) => fund.id),
                contacts: this.contacts.map((contact) => contact.id),
            };
        },
        config() {
            return {
                columns: [
                    {
                        headline: 'Fund',
                        sort: true,
                    },
                    {
                        headline: 'AUM',
                    },
                    {
                        headline: 'Contact',
                        sort: true,
                    },
                    {
                        headline: 'HCW Additional\nNames',
                        hidden: this.hiddenColumns.includes('hcwAdditionalNames'),
                    },
                    {
                        headline: 'On Company\nMet With List',
                        hidden: this.hiddenColumns.includes('onCompanyMetWithList'),
                    },
                    {
                        headline: 'On Company\nTarget List',
                        hidden: this.hiddenColumns.includes('onCompanyTargetList'),
                    },
                    {
                        headline: 'Meeting\nDate',
                        hidden: this.hiddenColumns.includes('meetingDate'),
                    },
                    {
                        headline: 'Meeting\nType',
                        hidden: this.hiddenColumns.includes('meetingType'),
                    },
                    {
                        headline: 'Last Peer Meeting',
                        hidden: this.hiddenColumns.includes('lastPeerMeeting'),
                    },
                    {
                        headline: 'Last\nFeedback',
                    },
                    {
                        headline: 'Current\nShareholder',
                        hidden: this.hiddenColumns.includes('currentShareholder'),
                        sort: true,
                    },
                    {
                        headline: 'Peer\nShareholder',
                        hidden: this.hiddenColumns.includes('peerShareholder'),
                    },
                    {
                        headline: 'Shareholder\n(manual)',
                        hidden: this.hiddenColumns.includes('manualShareholder'),
                    },
                    {
                        headline: 'Do not contact',
                        hidden: this.hiddenColumns.includes('doNotContact'),
                    },
                    {
                        headline: 'HCW Status',
                        hidden: this.hiddenColumns.includes('hcwStatus'),
                    },
                    {
                        headline: 'HCW Comments',
                        hidden: this.hiddenColumns.includes('hcwComments'),
                    },
                    {
                        headline: 'Company Comments',
                        hidden: this.hiddenColumns.includes('companyComments'),
                    },
                    {
                        headline: 'Tier',
                        hidden: this.hiddenColumns.includes('tier'),
                    },
                    {
                        headline: 'Initial Outreach',
                        hidden: this.hiddenColumns.includes('initialOutreach'),
                    },
                    {
                        headline: 'OTW',
                        hidden: this.hiddenColumns.includes('otw'),
                    },
                    {
                        headline: 'OTW Date',
                        hidden: this.hiddenColumns.includes('otwDate'),
                    },
                    {
                        headline: 'Outreach',
                        hidden: this.hiddenColumns.includes('outreach'),
                    },
                    {
                        headline: 'Outreach User',
                        hidden: this.hiddenColumns.includes('outreachUser'),
                    },
                    {
                        headline: 'CDA\nSigned',
                        hidden: this.hiddenColumns.includes('cdaSigned'),
                    },
                    {
                        headline: 'Data Room\nAccess Given',
                        hidden: this.hiddenColumns.includes('dataRoomAccessGiven'),
                    },
                    {
                        headline: 'Data Room\nAccessed',
                        hidden: this.hiddenColumns.includes('dataRoomAccessed'),
                    },
                    {
                        headline: 'Feedback',
                        hidden: this.hiddenColumns.includes('feedback'),
                    },
                    {
                        headline: 'Other',
                        hidden: this.hiddenColumns.includes('other'),
                    },
                    {
                        headline: 'Actions',
                        hideHeadlineBreakpoint: 'all',
                    },
                ],
                ajaxUrl: '/api/targeting_group_members/list',
                pagination: 25,
                search: true,
                select: true,
                selectPosition: 'pre',
                prettySelect: true,
                selectAll: true,
                pageSortSelect: true,
            };
        },
        selectedRowIds() {
            return this.selectedRows.map((r) => r.member_id);
        },
        canEdit() {
            return (
                this.isUserWithRoleCorporateAccess ||
                this.targetingGroup.editorUsers.map((u) => u.id).includes(this.userInfo.id)
            );
        },
    },
    watch: {
        selectedRows: {
            handler(rows) {
                if (!this.pageChanged && this.ajaxRows) {
                    for (let i = 0; i < rows.length; i++) {
                        if (!this.preSelectedRowIds.includes(rows[i].member_id)) {
                            this.preSelectedRowIds.push(rows[i].member_id);
                        }
                    }

                    for (let j = 0; j < this.ajaxRows.length; j++) {
                        const id = this.ajaxRows[j].member_id;

                        const index = this.preSelectedRowIds.indexOf(id);

                        if (!rows.map((r) => r.member_id).includes(id) && index !== -1) {
                            this.preSelectedRowIds.splice(index, 1);
                        }
                    }
                }
            },
            immediate: true,
        },
        targetingGroup: {
            handler() {
                if (this.targetingGroup.hiddenColumns && this.targetingGroup.hiddenColumns.length > 0) {
                    this.hiddenColumns = this.targetingGroup.hiddenColumns;
                } else {
                    this.hiddenColumns = [];
                }
            },
            immediate: true,
        },
    },
    methods: {
        ...mapActions({
            setBulkContactFiltersHash: 'app/setBulkContactFiltersHash',
        }),
        reloadTable() {
            this.tableUpdate = new Date();
        },
        removeMember(member) {
            if (!confirm('Are you sure you want to remove this member?')) {
                return;
            }

            this.$axios.delete(`/api/targeting_group_members/${member.id}`).then(() => {
                this.reloadTable();
            });
        },
        async downloadTargetingGroup() {
            const query = {
                search: this.$refs.targetingGroupTable.query,
                filters: this.filters,
            };
            this.groupDownloadQuery = query;
            await this.$nextTick();
            // this.saveQueryAndExport(query, '/api/targeting_group_members/excel');

            this.openModal(this.$refs.downloadTargetingMemberGroupModal);
        },
        async addMember() {
            this.addMemberModalKey = this.generateUUID();
            await this.$nextTick();
            this.modal = this.openModal(this.$refs.addMemberModal);
        },
        async openNoteModal(note) {
            this.noteToShow = note;
            this.noteToShowKey = this.generateUUID();
            await this.$nextTick();
            this.modal = this.openModal(this.$refs.showNoteModal);
        },
        closeActiveModal() {
            if (this.modal) {
                this.closeModal(this.modal);
                this.modal = null;
            }
        },
        onMembersAdded() {
            this.closeActiveModal();
            this.reloadTable();
        },
        async openEditColumnsModal() {
            this.updateColumnsModalKey = 'update-columns-modal-key-' + Math.random().toString(36).substring(7);
            await this.$nextTick();
            this.modal = this.openModal(this.$refs.updateColumnsModal);
        },
        onColumnsUpdated(targetingGroup) {
            this.closeActiveModal();

            if (targetingGroup.hiddenColumns && targetingGroup.hiddenColumns.length > 0) {
                this.hiddenColumns = targetingGroup.hiddenColumns;
            } else {
                this.hiddenColumns = [];
            }
        },
        async addContact(item) {
            this.targetingGroupMemberToEdit = item;
            await this.$nextTick();
            this.modal = this.openModal(this.$refs.addCompanyContactModal);
        },
        removeContact(item) {
            if (!confirm('Are you sure you want to remove this contact?')) {
                return;
            }

            this.$axios
                .patch(
                    `/api/targeting_group_members/${item.id}/remove_contact`,
                    {},
                    {
                        headers: {
                            'Content-Type': 'application/merge-patch+json',
                        },
                    }
                )
                .then(() => {
                    this.reloadTable();
                });
        },
        onContactAddedToCompany() {
            if (this.modal) {
                this.closeModal(this.modal);
                this.modal = null;
            }
            this.reloadTable();
        },
        updateShareholderInfo() {
            this.isUpdatingShareholderInfo = true;
            this.$axios
                .post(`/api/targeting_group/${this.targetingGroup.id}/update_ownerships`, {})
                .then(() => {
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: 'success',
                        title: 'Success',
                        message:
                            "The shareholder information will be updated in the background. We will notify you when it's done.",
                        id: toastId,
                    });
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    });
                })
                .finally(() => {
                    this.isUpdatingShareholderInfo = false;
                });
        },
        onRowClick(row) {
            this.ajaxRows.forEach((ajaxRow) => {
                ajaxRow.classes = '';
            });
            row.classes = 'row-in-front';
        },
        onRowsUpdated(data) {
            this.pageChanged = false;
            if (data && data.rows && data.rows.length) {
                this.preSelectedRows = JSON.parse(JSON.stringify(this.preSelectedRowIds)).map((id) => {
                    return {
                        key: 'member_id',
                        value: id,
                    };
                });
            }
            this.ajaxRows = data.rows;
            this.ajaxAll = data.all;
        },
        deleteTargetingGroup() {
            if (!confirm('Are you sure you want to delete this group?')) {
                return;
            }

            this.$axios.delete(`/api/targeting_groups/${this.targetingGroup.id}`).then(() => {
                const toastId = this.generateUUID();
                this.addToast({
                    type: 'success',
                    title: 'Success',
                    message: 'Targeting Group has been deleted.',
                    id: toastId,
                });
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                });

                this.$router.push(`/companies/targeting/${this.targetingGroup.company.id}`);
            });
        },
        selectStatuses(open) {
            this.hcwStatuses = open ? OPEN_HCW_STATUSES : CLOSED_HCW_STATUSES;
        },
        clearPreSelection() {
            this.preSelectedRows = [];
            this.preSelectedRowIds = [];
            this.selectedRows = [];
        },
        onPageChange() {
            this.pageChanged = true;
        },
        openBulkOperationModal(type) {
            if (this.modal) {
                this.closeModal(this.modal);
            }
            // Load contacts
            let filterForQuery = null;
            if (type === 'selected') {
                filterForQuery = { ids: this.preSelectedRowIds };
            } else if (type === 'filtered') {
                filterForQuery = this.filters;
            }

            this.$axios
                .post('/api/targeting_group_members/bulk_operation/prepare', { filters: filterForQuery })
                .then(async (response) => {
                    this.bulkContacts = {
                        hash: response.data.contactsHash,
                        count: response.data.contactsCount,
                    };
                    this.bulkMembers = {
                        hash: response.data.membersHash,
                        count: response.data.membersCount,
                    };
                    await this.setBulkContactFiltersHash(response.data.contactsHash);
                    this.targetingGroupModalKey = this.generateUUID();
                    await this.$nextTick();
                    this.modal = this.openModal(this.$refs.bulkOperationModal);
                });
        },
        onBulkSubmitted() {
            if (this.modal) {
                this.closeModal(this.modal);
                this.modal = null;
            }
            this.clearPreSelection();
            this.reloadTable();
        },
        resetFilters() {
            this.hcwAdditionalNames = null;
            this.onCompanyMetWithList = null;
            this.onCompanyTargetList = null;
            this.cdaSigned = null;
            this.dataRoomAccessed = null;
            this.hcwStatuses = [];
            this.funds = [];
            this.contacts = [];
        },
        downloadBulkContacts(filters) {
            if (this.modal) {
                this.closeModal(this.modal);
            }
            this.downloadQuery = {
                filters,
            };
            this.openModal(this.$refs.downloadContactsModal);
        },
        openEditorsModal() {
            if (this.modal) this.closeModal(this.modal);
            this.$refs.manageEditorsModal.resetWorkingCopy();
            this.modal = this.openModal(this.$refs.manageEditorsModal);
        },
        mouseover() {
            this.tooltip(true);
            this.tooltipLock = true;
            // don't hide the tooltip again if the user hovers and clicks immediately
            setTimeout(() => {
                this.tooltipLock = false;
            }, 500);
        },
        tooltip(show) {
            this.tooltipShown = show;
            if (this.tooltipShown) {
                const button = this.$refs['option-button-creator-info'].getBoundingClientRect();
                this.tooltipLeft = button.left + window.scrollX;
                this.tooltipTop = button.bottom + window.scrollY + 10;
            }
        },
    },
};
