
import moment from "moment";

export default {
    name: "PressReleasesTable",
    components: {},
    props: {
        userId: {
            type: Number,
            default: null
        },
        companiesHash: {
            type: String,
            default: null,
        },
        companyIds: {
            type: Array,
            default: () => []
        },
        symbols: {
            type: Array,
            default: () => []
        },
        type: {
            type: String,
            default: "press-releases",
            validator(val) {
                return ["press-releases", "media-coverage", "all"].includes(val);
            }
        },
        pageSize: {
            type: Number,
            default: 5,
        },
        saOnly: {
            type: Boolean,
            default: false,
        },
        select: {
            type: Boolean,
            default: false,
        },
        limit: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            updated: null,
            sortOrder: [{
                index: 0,
                asc: false,
            }],
            key: moment().unix(),
            filters: {
                relevantForUserId: this.userId,
                companyIds: this.companyIds,
                companiesHash: this.companiesHash,
                symbols: this.symbols,
                saOnly: this.saOnly,
            },
            selectedRows: [],
            pageChanged: false,
            ajaxRows: null,
            ajaxAll: null,
            preSelectedRows: [],
            preSelectedRowData: [],
        }
    },
    computed: {
        option() {
            switch (this.type) {
                case "all":
                    return 0;
                case "media-coverage":
                    return 2;
                case "press-releases":
                default:
                    return 1;
            }
        },
        config() {
            return {
                columns: [
                    {
                        headline: "Date",
                        sort: !this.select
                    }, {
                        headline: "Symbol",
                        hidden: !(this.userId > 0) || this.symbols.length === 1,
                    },
                    {
                        headline: "Title",
                        sticky: true,
                        hideHeadlineBreakpoint: "all",
                    },
                    {
                        headline: "Text",
                        breakpoint: "all",
                        align: "start",
                    }, {
                        headline: "Link",
                        breakpoint: "all",
                        align: "start",
                    }, {
                        headline: "Sourced through",
                        breakpoint: "all",
                        align: "start",
                    }
                ],
                pagination: this.pageSize,
                ajaxUrl: "/api/companies/press_releases?option=" + this.option + (this.limit !== null ? "&limit=" + this.limit : ""),
                selectAll: this.select,
                select: this.select,
                prettySelect: true,
                selectPosition: "pre",
                search: this.select,
            }
        },
    },
    watch: {
        selectedRows(rows) {
            if (!this.pageChanged && this.ajaxRows) {
                for (let i = 0; i < rows.length; i++) {
                    if (!this.preSelectedRowData.includes(rows[i].selector)) {
                        this.preSelectedRowData.push(rows[i].selector);
                    }
                }

                for (let j = 0; j < this.ajaxRows.length; j++) {
                    const id = this.ajaxRows[j].selector;

                    const index = this.preSelectedRowData.indexOf(id);

                    if (!rows.map(r => r.selector).includes(id) && index !== -1) {
                        this.preSelectedRowData.splice(index, 1);
                    }
                }
            }
        }
    },
    methods: {
        onRowsUpdated(data) {
            this.pageChanged = false;
            if (data && data.rows && data.rows.length) {
                this.preSelectedRows = this.preSelectedRowData.map(s => {
                    return {
                        key: "selector",
                        value: s
                    }
                });
            }

            this.ajaxRows = data.rows;
            this.ajaxAll = data.all;
        },
        onPageChange() {
            this.pageChanged = true;
        },
        clearPreSelection() {
            this.preSelectedRowData = [];
            this.preSelectedRows = [];
        },
        downloadCompanies() {
            const filters = JSON.stringify({
                relevantForUserId: this.userId,
                companyIds: this.companyIds,
                companiesHash: this.companiesHash,
                symbols: this.symbols,
            });

            const url = "/api/companies/press_releases/export?option=" + this.option + "&" + this.buildQueryString({filters});
            window.open(url, '_blank').focus();
        }
    }

}
