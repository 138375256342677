export const columnLabels = {
    aum: 'Assets under Management (Export only)',
    hcwAdditionalNames: 'HCW Additional Names',
    onCompanyMetWithList: 'On Company Met With List',
    onCompanyTargetList: 'On Company Target List',
    meetingDate: 'Meeting Date',
    meetingType: 'Meeting Type',
    lastPeerMeeting: 'Last Peer Meeting',
    currentShareholder: 'Current Shareholder',
    peerShareholder: 'Peer Shareholder',
    manualShareholder: 'Manual Shareholder',
    doNotContact: 'Do Not Contact',
    hcwStatus: 'HCW Status',
    hcwComments: 'HCW Comments',
    companyComments: 'Company Comments',
    tier: 'Tier',
    initialOutreach: 'Initial Outreach',
    otw: 'OTW',
    otwDate: 'OTW Date',
    outreach: 'Outreach',
    outreachUser: 'Outreach User',
    cdaSigned: 'CDA Signed',
    dataRoomAccessGiven: 'Data Room Access Given',
    dataRoomAccessed: 'Data Room Accessed',
    feedback: 'Feedback',
    other: 'Other',
};
