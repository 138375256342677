

import LoadingSpinner from "../../loading-spinner.vue";

export default {
    name: "AutosaveCellUser",
    components: {LoadingSpinner},
    props: {
        initialValue: {
            // eslint-disable-next-line vue/require-prop-type-constructor
            type: String|Number,
            required: false,
            default: null
        },
        ownerId: {
            type: Number,
            required: true
        },
        attribute: {
            type: String,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            value: null,
            allowUpdates: false,
            loading: false,
        }
    },
    watch: {
        value() {
            console.log(this.value);
            this.submitUpdate();
        },
    },
    async created() {
        // eslint-disable-next-line
        this.value = this.initialValue;
        await this.$nextTick();
        this.allowUpdates = true;
    },
    methods: {
        submitUpdate() {
            if (!this.allowUpdates) {
                return;
            }

            this.loading = true;
            this.$axios.patch(`/api/targeting_group_members/${this.ownerId}/set_outreach_user`, {
                userId: this.value?.id,
            }, {
                headers: {
                    'Content-Type': 'application/merge-patch+json'
                }
            }).catch(() => {
                this.$emit("error");
            }).finally(() => {
                this.loading = false;
            });
        }
    },
}
