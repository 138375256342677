
import { columnLabels } from './constants';

export default {
    name: 'ColumnList',
    props: {
        statusByColumn: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            localStatusByColumn: this.statusByColumn,
        };
    },
    methods: {
        getColLabel(label) {
            return columnLabels[label];
        },
    },
};
